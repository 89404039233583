import JumpToTheTopButton from 'components/Home/JumpToTheTopButton';
import { LayoutWrapper } from 'components/LayoutWrapper';
import componentsMap from 'components/componentsMap';
import { WagtailPage } from 'types/pageTypes';

const PageConstructor: React.FC<WagtailPage> = ({ body }) => {
    return (
        <LayoutWrapper>
            <JumpToTheTopButton />

            {body.map((component) => {
                const componentKey =
                    component.type as keyof typeof componentsMap;

                const Component = componentsMap[componentKey] as React.FC;

                if (!Component) return null;

                const isLink =
                    componentKey === 'external_link' ||
                    componentKey === 'internal_link';

                const isRichText = componentKey === 'rich_text';

                if (isRichText) component.className = 'centeredWithMargin';
                if (isLink) component.className = 'uniqueComponent';

                return <Component key={component.id} {...component} />;
            })}
        </LayoutWrapper>
    );
};

export default PageConstructor;
