import { NextPage } from 'next';
import { basePageWrap } from 'containers/BasePage';
import { HomePageType } from 'types/pageTypes';
import PageConstructor from 'components/PageConstructor/PageConstructor';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const HomePage: NextPage<HomePageType> = (props) => {
    const router = useRouter();

    useEffect(() => {
        if (router.query.account_id) {
            router.push('/accounts/auth0/login/');
        }
    }, [router.query]);

    return <PageConstructor {...props} />;
};

export default basePageWrap(HomePage);
